<template>
  <v-alert
    border="left"
    text
    class="alert-box-container"
    :type="type"
  >
    <span>{{ title }}</span>
    <ul class="alert-box-item" v-if="errors && Array.isArray(errors) && errors.length != 0">
      <li v-for="(error, errorIndex) in errors" :key="errorIndex">{{ error }}</li>
    </ul>
  </v-alert>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    errors: {
      type: [String, Array],
      required: false
    },
  },
}
</script>

<style scoped>
.alert-box-container {
  width: 100% !important;
  text-align: left;
}
.alert-box-item {
  text-align: left;
}
</style>