<template>
  <v-btn
    block
    :color="color == '' ? 'primary-color' : color"
    class="primary-text--text"
    :disabled="loading || disabled"
    large
    elevation="0"
    @click.prevent="press()"
    :loading="loading"
  >
    <slot></slot>
  </v-btn>
</template>

<script>

export default {
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    color: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    press() {
      this.$emit('whenClick');
    }
  }
}
</script>

<style scoped>
</style>