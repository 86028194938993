<template>
  <v-text-field
    :name="text"
    class="login-email-input"
    v-model="text"
    filled
    :type="type"
    dense
    :prepend-inner-icon="icon"
    color="primary-color"
    :label="placeholder"
    :hide-details="hide_details"
    :maxlength="counter"
    :counter="counter && counter > 0"
    :rules="generatedRules"
    :disabled="disabled"
    :readonly="readonly"
    @blur="$emit('blur')"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: false
    },
    error: {
      type: Boolean,
      required: false
    },
    icon: {
      type: String,
      required: false
    },
    hide_details: {
      type: Boolean,
      default: false
    },
    counter: {
      type: [Number, Boolean],
      default: false
    },
    must: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    blur: {
      type: Function,
    }
  },
  data() {
    return {
      text: '',
      generatedRules: [],
      rules: {
        required: value => !!value || 'Requerido.',
        counter: value => value.length <= this.counter || 'Maximo ' + this.counter + ' caracteres',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
        //min: value => (parseInt(value) <= 0) ? 'No puede ser menor o igual a cero' : false,
      },
    }
  },
  created() {
    if (this.must.find(e => e == 'email')) {
      this.generatedRules.push(this.rules.email);
    }
    if (this.must.find(e => e == 'required')) {
      this.generatedRules.push(this.rules.required);
    }
    if (this.counter) {
      this.generatedRules.push(this.rules.counter);
    }
    if (this.value) {
      this.text = this.value;
    }
    // if (this.type == 'number') {
    //   this.generatedRules.push(this.rules.min);
    // }
  },
  watch: {
    text() {
      this.$emit('text', this.text);
    },
    value() {
      if (this.text != this.value) {
        this.text = this.value;
      }
    }
  }
}
</script>

<style scoped>
.login-email-input {
  width: 100%;
}
.v-input__slot {
  margin-bottom: 0px !important;
}
</style>