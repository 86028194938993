<template>
  <div class="logo-component-container py-16">
    <h2>Ingresar Código</h2>
    <p>
      Ingresa aquí el código que te llegó al correo para poder reiniciar la contraseña
    </p>
    <div class="login-form-container">
      <div class="login-form">
        <transition name="fadeHeight" mode="out-in">
          <alert-box
            v-if="error != '' || (Array.isArray(error) && error.length != 0)"
            class="login-alert-box my-2"
            type="error"
            :title="'Datos erróneos, favor de verificar'"
            :errors="error"
          />
        </transition>
        <input-text
          class="mt-3"
          placeholder="Dirección de Correo"
          icon="mdi-at"
          @text="(value) => { form.email = value }"
          :error="errorEmail"
        />
        <input-password
          :must="['required']"
          placeholder="Código"
          @password="(value) => { form.code = value }"
        />
        <input-password
          :must="['required']"
          placeholder="Nueva Contraseña"
          @password="(value) => { form.password = value }"
        />
        <input-password
          :must="['required']"
          placeholder="Confirmar Nueva Contraseña"
          @password="(value) => { form.passwordConfirm = value }"
        />
        <button-component
          @whenClick="submit()"
          :loading="!canSubmit"
          :disabled="disabled"
        >
          Cambiar Contraseña
        </button-component>
      </div>
    </div>
    <div class="login-link-group">
      <router-link to="/login" class="login-link link-text--text">¿Ya tienes una cuenta?</router-link>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import InputText from '@/components/form/InputText.vue';
import AlertBox from '@/components/form/AlertBox.vue';
import Button from '@/components/form/Button.vue';
import InputPassword from '@/components/form/InputPassword.vue';

export default {
  name: 'login',
  components: {
    'input-text': InputText,
    'button-component': Button,
    'alert-box': AlertBox,
    'input-password': InputPassword,
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
        passwordConfirm: '',
        code: '',
        remember: false,
      },
      errorEmail: false,
      show_pass: false,
      error: '',
      canSubmit: true,
      disabled: true,
    };
  },
  methods: {
    ...mapActions('main', [
      'loginProvisorio',
    ]),
    ...mapActions('authentication', ['login']),
    validateEmail: (email) => {
      const regex = /\S+@\S+\.\S+/;
      return regex.test(email);
    },
    submit() {
      if (!this.canSubmit) {
        return;
      }
      // Validation
      this.setError();
      if (!this.validateEmail(this.form.email)) {
        this.setError('Has ingresado una dirección de correo no válida!');
        return;
      }
      
      // Login Call
      this.canSubmit = false;
      this.loginProvisorio({
        email: this.form.email,
        code: this.form.code,
        password: this.form.password,
        passwordConfirm: this.form.passwordConfirm
      })
        .then(() => {
          this.login({
            username: this.form.email,
            password: this.form.password
          })
            .then(() => {
              this.$router.replace('home');
            })
            .catch(err => console.log(err));
        })
        .catch(err => {
          console.log(err);
          this.error = ['El código no coincide.'];
          setTimeout(() => {
            this.error = "";
          }, 10000);
          this.canSubmit = true;
        });
    },
    setError(error) {
      if (!error) {
        this.error = [];
      } else {
        this.error.push(error);
      }
    },
    validatePasswords() {
      if (this.form.password != '' && this.form.passwordConfirm != '' && this.form.password == this.form.passwordConfirm) {
        this.disabled = false;
        this.error = '';
      } else if (this.form.password != this.form.passwordConfirm) {
        this.error = ['Las contraseñas no coinciden'];
        setTimeout(() => {
          this.error = '';
        },10000);
        this.disabled = true;
      } else {
        this.disabled = true;
      }
    }
  },
  watch: {
    'form.email'() {
      if (this.form.email != '') {
        this.errorEmail = !this.validateEmail(this.form.email);
      } else {
        this.errorEmail = false;
      }
    },
    'form.password'() {
      this.validatePasswords();
    },
    'form.passwordConfirm'() {
      this.validatePasswords();
    },
  }
};
</script>

<style scoped>
.logo-component-container {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 500px;
  width: 100%;
}
.login-link-group {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.login-link {
  cursor: pointer;
  text-decoration: underline;
}
.login-link:not(:last-child) {
  margin-right: 1em;
}
.login-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
  width: 100%;
}
.login-form .login-margin {
  margin-bottom: 1em;
}
.login-remember {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.login-remember div {
  width: 50%;
}
.login-checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.login-forgot {
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}
.login-alert-box {
  margin-bottom: 0px;
}
.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.2s;
  max-height: 230px;
}
.fadeHeight-enter,
.fadeHeight-leave-to
{
  opacity: 0;
  max-height: 0px;
}
</style>
