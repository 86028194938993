<template>
  <v-text-field
    name="password"
    class="input-password-component"
    v-model="password"
    filled
    dense
    :label="placeholder ? placeholder : 'Enter password'"
    :append-icon="!show_pass ? 'mdi-eye-off' : 'mdi-eye'"
    :prepend-inner-icon="icon"
    color="primary-color"
    @click:append="() => (show_pass = !show_pass)"
    :type="!show_pass ? 'password' : 'text'"
    :rules="generatedRules"
    :hide-details="hide_details"
    v-on:keyup.enter="actionPressEnter"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      default: 'mdi-lock',
      required: false
    },
    must: {
      type: Array,
      default: () => [],
    },
    hide_details: {
      type: Boolean,
      default: false
    },
    actionEnter: {
      type: Function,
    }
  },
  methods: {
    actionPressEnter(){
      this.actionEnter();
    }
  },
  data() {
    return {
      password: '',
      show_pass: false,
      generatedRules: [],
      rules: {
        required: value => !!value || 'Requerido.',
      }
    }
  },
  created() {
    if (this.must.find(e => e == 'required')) {
      this.generatedRules.push(this.rules.required);
    }
  },
  watch: {
    password() {
      this.$emit('password', this.password);
    }
  }
}
</script>

<style scoped>
.input-password-component {
  width: 100%;
}
</style>